<template>
  <div>
    <statistics
      :data="statistic"
    />

    <full-table
      :other-filter-props="{
        search: true,
        gender: true,
        tags: true,
        role: true,
        importButton: true
      }"
      :items="GET_USERS_LIST.results"
      :fields="fields"
      :rows="GET_USERS_LIST.count"
      :edit-btn="false"
      :first-params="params"
      :empty-text="$t('general.no_data_to_view')"
      @import="importUsers"
      @refresh="FETCH_USERS_LIST"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @delete-button-clicked="modalDeleteUser"
      @add-button-clicked="addUser"
    />

    <add-modal
      :default-params="params"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import DeleteModal from '@/components/DeleteModal.vue'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import FullTable from '@/components/FullTable.vue'
import Statistics from '../../Home/components/Statistics.vue'
import AddModal from '../../Users/components/modals/AddUserModal.vue'

export default {
  name: 'User',
  components: {
    Statistics,
    AddModal,
    FullTable,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isEdit: false,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
        branch_id: this.$route.params.id,
      },
      fields: [
        {
          label: 'ID',
          key: 'contact.id',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          label: 'users.roles',
          key: 'roles',
        },
        {
          label: 'users.phone_number',
          key: 'phones',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
    ...mapGetters('users', ['GET_DASHBOARD_STATISTICS']),
    statistic() {
      return [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: this.GET_DASHBOARD_STATISTICS.all_users,
          subtitle: this.$t('users.total_users'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: this.GET_DASHBOARD_STATISTICS.staff,
          subtitle: this.$t('users.stuffs'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-danger',
          title: this.GET_DASHBOARD_STATISTICS.teachers,
          subtitle: this.$t('users.teachers'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-success',
          title: this.GET_DASHBOARD_STATISTICS.students,
          subtitle: this.$t('users.students'),
          customClass: '',
        },
      ]
    },
  },
  mounted() {
    this.FETCH_DASHBOARD_STATISTICS({ branch_id: this.$route.params.id })
    this.FETCH_BRANCH(this.$route.params.id)
      .then(res => {
        this.updateBreadcrumb(res.data)
      })
  },
  methods: {
    ...mapActions('users', ['FETCH_DASHBOARD_STATISTICS', 'FETCH_USERS_LIST', 'DELETE_USER']),
    ...mapActions('branches', ['FETCH_BRANCH']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    addUser() {
      this.$bvModal.show('AddModalUser')
    },
    refresh() {
      this.FETCH_USERS_LIST(this.params)
    },
    importUsers() {
      this.$bvModal.show('import-user-modal')
    },
    updateBreadcrumb(res) {
      console.log(res)
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = `${res.title}`
      this.UPDATE_BREADCRUMB([first, second])
    },
    modalDeleteUser(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.FETCH_USERS_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>
